.ant-table-body {
  overflow: auto !important;
  padding-bottom: 10px;
}

.ant-menu-item .anticon {
  font-size: 13px !important;
}

.button-edit {
  color: white;
  background-color: #f1d43a;
  border-color: #f1d43a !important;
}

.button-edit:hover {
  background-color: #ffe03d !important;
  border-color: #ffe03d !important;
  color: white !important;
}


.ant-menu {
  background: rgba(255, 255, 255, 0.5) !important;
}

.question-answer-card .ant-form-item {
  margin-bottom: 5px;
}

.ant-menu-item {
  margin-top: 3.5px;
  margin-bottom: 7px;
  font-size: 13px !important;
  background-color: transparent;
}

.ant-menu-sub {
  background-color: transparent !important;
}

.logo-container {
  -webkit-box-shadow: -3px 1px 7px -1px rgba(128, 128, 128, 1);
  -moz-box-shadow: -3px 1px 7px -1px rgba(128, 128, 128, 1);
  box-shadow: -3px 1px 7px -1px rgba(128, 128, 128, 1);
}

.logo {
  height: 30px;
}

::-webkit-scrollbar {
  border-radius: 20px;
  background-color: #F5F5F5;
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.37);
}

.ant-layout-sider-dark {
  z-index: 1;
  -webkit-box-shadow: 1px 1px 11px 0px rgba(128, 128, 128, 1);
  -moz-box-shadow: 1px 1px 11px 0px rgba(128, 128, 128, 1);
  box-shadow: 1px 1px 11px 0px rgba(128, 128, 128, 1);
}


.components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

html, body, #root, .App {
  min-height: 100%;
  min-width: 100%;
}
