#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

:local(.logo) {
  width: auto;
  height: 94px;
  padding: 0px;
  margin-bottom: 0px;
  object-fit: contain;
}

.logo-container {
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf6f0;
}

.ant-menu-item {
  color: black !important;
}
